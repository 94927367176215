import React, {useEffect, useRef, useState} from "react";
import MainSectionTopFifteen from "../main-section-top-fifteen/main-section-topFifteen";
import {BreadcrumbNavigation} from "../breadcrumb-navigation/breadcrumbsNavigation";
import {TreemapComponent} from "./treemap/treemap";
import {TreemapMultipliersComponent} from "./treemapMultipliers/treemapMultipliers";
import arrowUp from "../../images/Arrow-full-up.svg";
import styles from './market-map.module.css';
import {AverageByIndex} from "./average-by-index/average-by-index";

const tabNameToHeader = {
    marketMap1: "Карта рынка",
    marketMap2: "Карта мультипликаторов",
    average: "Среднеисторические показатели",
    topFifteen: "Топ роста / падения"
};

export const MarketMap = () => {
    const topFifteenRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [activeButton, setActiveButton] = useState('marketMap1');
    const [isVisible, setIsVisible] = useState(false);

    const changeTab = (tabName) => setActiveButton(tabName)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div className={styles.marketMapContainer}>
            <BreadcrumbNavigation links={[{ label: "Главная", url: "/" }]} forBg="light" className={styles.breadcrumbs} />
            <div className={`${styles.marketMapHeader} ${isFullscreen && styles.collapseFullscreen}`}>
                <h1>{tabNameToHeader[activeButton]}</h1>
            </div>

            <div className={styles.scrollBtnsContainer}>
                <button onClick={() => changeTab('marketMap1')}
                        className={`${styles.scrollBtn} ${activeButton === 'marketMap1' ? styles.activeScrollBtn : ''}`}
                >Карта рынка
                </button>
                <button onClick={() => changeTab('marketMap2')}
                        className={`${styles.scrollBtn} ${activeButton === 'marketMap2' ? styles.activeScrollBtn : ''}`}
                >Карта мультипликаторов
                </button>
                <button onClick={() => changeTab("average")}
                        className={`${styles.scrollBtn} ${activeButton === 'average' ? styles.activeScrollBtn : ''}`}
                >Среднеисторические показатели
                </button>
                <button onClick={() => changeTab('topFifteen')}
                        className={`${styles.scrollBtn} ${activeButton === 'topFifteen' ? styles.activeScrollBtn : ''}`}
                >Топ роста / падения
                </button>
            </div>
            {activeButton === 'marketMap1' && <TreemapComponent isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}/>}
            {activeButton === 'marketMap2' && <TreemapMultipliersComponent isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}/>}
            {activeButton === "average" && <AverageByIndex />}
            {activeButton === 'topFifteen' && <div className={styles.topFifteenContainer} ref={topFifteenRef}><MainSectionTopFifteen/></div>}
            {isVisible && (
                <button className={styles.scrollToTopBtn} onClick={scrollToTop}>
                    <img src={arrowUp} alt="arrow up"/>
                </button>
            )}
        </div>
    );
}
