import styles from "./benefits.module.css";
import ArrowRight from "../../images/benefits/ArrowRight.svg";
import MoneyCase from "../../images/benefits/MoneyCase.svg";
import Cup from "../../images/benefits/Cup.svg";
import Tag from "../../images/benefits/tag.svg";
import Telegram from "../../images/benefits/Telegram.svg";
import clsx from "classnames";
import {Button, Input} from "antd";
import {NavLink} from "react-router-dom";

export const Benefits = () => {
  return (
    <div className={styles.benefitsWrapper}>
      <div className={clsx(styles.benefits, "content")}>
        <h2 className={styles.title}>Преимущества для клиентов Invest Era</h2>
        <div className={styles.benefitsCompare}>
          <div className={clsx(styles.benefitsBlockCommonInvestor, styles.benefitsBlock)}>
            <h3 className={styles.benefitsListTitle}>Рядовой инвестор</h3>
            <ul className={styles.benefitsListCommonInvestor}>
              <li className={styles.benefit}>Может следить за 10-20 компаниями и пропускает большое число инвест идей
              </li>
              <li className={styles.benefit}>Во многих случаях сложно разобраться и оперативно принять решение</li>
              <li className={styles.benefit}>Не обладает важной информацией, что ведет к убыткам</li>
              <li className={styles.benefit}>Не знает будут ли выплачены значительные дивиденды и не может купить акцию
                заранее
              </li>
              <li className={styles.benefit}>Не знает ситуацию с макроиндикаторами, прогноз динамики рынка осложнен</li>
            </ul>
          </div>
          <div className={clsx(styles.benefitsBlockIE, styles.benefitsBlock)}>
            <div className={styles.benefitsListHeaderIE}>
              <h3 className={styles.benefitsListTitle}>Invest Era</h3>
              <div className={styles.cupWrapper}>
                <img className={styles.cup} src={Cup} alt=""/>
              </div>
            </div>
            <ul className={styles.benefitsListIE}>
              <li className={styles.benefit}>Мы детально знаем ситуацию более чем в 130 компаниях и находим много
                возможностей для увеличения
                доходности
              </li>
              <li className={styles.benefit}>Быстро обрабатываем новости и принимаем решения</li>
              <li className={styles.benefit}>Общаемся напрямую с компаниями, у нас больше информации чем у 99%
                участников рынка
              </li>
              <li className={styles.benefit}>Используем собственные модели для прогнозирования прибылей и дивидендов
                компаний, что позволяет их
                купить до того как это сделает большинство
              </li>
              <li className={styles.benefit}>Анализируем макроэкономические данные и действия крупных игроков</li>
            </ul>
          </div>
          <div className={styles.benefitsCompareArrow}>
            <img src={ArrowRight} alt=""/>
          </div>
        </div>
      </div>
      <div className="content">
        <div className={clsx(styles.bestAnalytics)}>
          <div className={styles.bestAnalyticsPortfolio}>
            <div className={styles.gradient}></div>
            <p className={styles.portfolioText}>Портфель облигаций</p>
            <p className={styles.portfolioDescription}>Средняя доходность к погашению</p>
            <img className={styles.moneyCase} src={MoneyCase} alt=""/>
            <img className={styles.tag} src={Tag} alt=""/>
          </div>
          <div className={styles.bestAnalyticsAnalytics}>
            <div className={styles.bestAnlyticsSubscribe}>
              <p className={styles.bestAnalyticsText}>
                Лучшая аналитика за неделю
              </p>
              <form className={styles.subscribeForm} onSubmit={e => e.preventDefault()}>
                <Input rootClassName={styles.emailInput} placeholder="Адрес электронной почты"/>
                <button className={styles.subscribeButton}>Подписаться</button>
              </form>
              <p className={styles.subscribeWarn}>Нажимая "Подписаться", я соглашаюсь с условиями <br/>
                <NavLink className={styles.politicsLink} to="/info/politika-konfidentsialnosti">Политики
                  Конфиденциальности</NavLink>
              </p>
            </div>
            <div className={styles.bestAnalyticsQuestions}>
              <p className={styles.bestAnalyticsQuestionsDescription}>Есть вопросы?&nbsp;</p>
              <a className={styles.bestAnalyticsQuestionsLink} href="">Напишите нам в Telegram&nbsp;&nbsp;</a>
              <img className={styles.bestAnalyticsQuestionsImage} src={Telegram} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
