import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import Graph1Img from '../../../../../../../images/rus1-unity-graph.svg';
// import Graph1Img from '../../../../../../../images/long-term-portfolio-rf.svg';
import LastBlock from './last-block/last-block';
import styles from './gallery-tables.module.css';

function GalleryTablesProduct() {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const hideBlock = useMediaQuery({ query: '(max-width: 310px)' });
  const [swiper, setSwiper] = useState(null);

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.navigationBox}>
          <div className={styles.chartsTitle}>Наша доходность</div>
        </div>

        <div className={styles.slider}>
          {!isMobile && (
            <Swiper
              onInit={(swiper) => setSwiper(swiper)}
              slidesPerView={2.5}
              spaceBetween={20}
              modules={[Autoplay, EffectCoverflow]}
              className={styles.swiper}
              style={{ '--swiper-theme-color': '#06813E' }}
              loop={false}
              rewind={false}
              scroll={false}>
              <SwiperSlide>
                <img src={Graph1Img} className={styles.img} />
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.img}>
                  <LastBlock subName={'Unity Россия'} subHref={'PriceSection'} />
                </div>
              </SwiperSlide>
            </Swiper>
          )}

          {isMobile && (
            <Swiper
              onInit={(swiper) => setSwiper(swiper)}
              slidesPerView={2}
              spaceBetween={8}
              modules={[Autoplay, EffectCoverflow]}
              className={styles.swiper}
              style={{ '--swiper-theme-color': '#06813E' }}
              loop={false}
              rewind={false}>
              <SwiperSlide>
                <img src={Graph1Img} alt={'Долгосрочный портфель РФ'} className={styles.img}/>
              </SwiperSlide>
              {!hideBlock && (
                <SwiperSlide>
                  <div className={styles.img}>
                    <LastBlock subName={'Unity Россия'} subHref={'PriceSection'} />
                  </div>
                </SwiperSlide>
              )}
              <SwiperSlide />
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
}

export default GalleryTablesProduct;
