import React, {useEffect, useRef, useState} from "react";
import {ConfigProvider, Radio, Select} from "antd";
import styles from "./average-by-index.module.css";

import arrowDownGray from "../../../images/arrow-down-sign-to-navigate-svg.svg";
import {getAverageByMonth} from "../../../utils/api";
import {selectIndexOptions, selectPeriodOptions} from "./average-by-index-utils";
import {createChart} from "lightweight-charts";
import {useResize} from "../../../services/hooks/useResize";
import clsx from "classnames";
import Preloader from "../../preloader/preloader";

export function AverageByIndex() {
  const [data, setData] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('peIndex');
  const [selectedPeriodId, setSelectedPeriodId] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  const handleMetricChange = (value) => {
    setSelectedMetric(value);
  };

  const handlePeriodChange = (e) => {
    setSelectedPeriodId(e.target.value);
  }

  useEffect(() => {
    setIsLoading(true);
    getAverageByMonth()
      .then(fetchedDates => {
        setData(fetchedDates);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useResize();

  const chartContainerRef = useRef();
  useEffect(() => {
    if (data.length === 0) return;
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 500,
      layout: {
        textColor: 'black',
        background: {type: 'solid', color: 'white'},
      },
      timeScale: {
        secondsVisible: false,
      },
      localization: {
        dateFormat: 'dd MMM yyyy',
      }
    });

    chart.timeScale().fitContent();

    const selectedPeriodCount = selectPeriodOptions.find(p => p.id === selectedPeriodId).count;
    const now = Date.now();
    const transformedData = data
      .filter(element => {
        return (element.timestamp) > now - selectedPeriodCount * 24 * 60 * 60 * 1000;
      })
      .map(element => ({
        value: element[selectedMetric],
        time: element.time
      }));

    const series = chart.addLineSeries({
      color: '#26a69a',
      lineWidth: 2,
    });
    series.setData(transformedData);

    return () => {
      chart.remove();
    };
  }, [data, chartContainerRef?.current?.clientWidth, selectedMetric, selectedPeriodId]);

  return (
    <ConfigProvider theme={{
      token: {
        fontFamily: "Montserrat"
      }
    }}>
      <div className={styles.averageByIndex}>
        <div className={styles.selectors}>
          <Select
            id="metric"
            value={selectedMetric}
            onChange={handleMetricChange}
            options={selectIndexOptions}
            className={styles.metricSelector}
            placement="bottomLeft"
            transitionName=""
            suffixIcon={<img src={arrowDownGray} alt=""/>}
          />

          <Radio.Group className={styles.periodSelector} onChange={handlePeriodChange} value={selectedPeriodId}>
            {selectPeriodOptions.map(period => (
              <Radio.Button key={period.id}
                            value={period.id}>
                {period.name}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>

        <div className={clsx({
          [styles.chart]: true,
          [styles.chartLoading]: isLoading,
        })} ref={chartContainerRef}>
          {isLoading && <Preloader/>}
        </div>
      </div>
    </ConfigProvider>
  );
}