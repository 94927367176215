import styles from './beyond-index.module.css';
import clsx from "classnames";

import Chart1 from "../../images/beyond-index/chart1.png";
import Chart2 from "../../images/beyond-index/chart2.png";
import Chart3 from "../../images/beyond-index/chart3.png";
import Chart4 from "../../images/beyond-index/chart4.png";

export const BeyondIndex = () => {
  return (
    <div className={styles.beyondIndexWrapper}>
      <div className={styles.gradient}/>
      <div className={clsx(styles.beyondIndex, "content")}>
        <div className={styles.chartContainer}>
          <img className={styles.chart} src={Chart1} alt=""/>
        </div>
        <div className={styles.textBlock}>
          <h2 className={styles.title}>Как Invest Era опережает индексы</h2>
          <ul className={styles.list}>
            <li className={styles.listItem}>Мы детально знаем ситуацию более более чем в 300 компаниях из США, Китая и других рынков</li>
            <li className={styles.listItem}>Быстро обрабатываем новости и принимаем решения</li>
            <li className={styles.listItem}>Используем большое количество иностранных платных подписок и делаем выжимку для подписчиков</li>
            <li className={styles.listItem}>Анализируем макроэкономические данные и действия инсайдеров</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
