import {useCallback, useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useMediaQuery} from "react-responsive"
import {useActiveFilters} from "../../services/context/ActiveFiltersContext"
import {setIsLoading} from "../../services/reducers/preloader-slice"
import Preloader from "../preloader/preloader"
import {NewsElement} from "./news-element";
import {useResize} from "../../services/hooks/useResize";
import {NewsCard} from "../card/news-card/news-card";
import Card from "../card/card";
import { v1 } from "uuid";
import styles from "./gallery.module.css"
import {ScrollToTop, ScrollToTopButton} from "../scroll-to-top/scroll-to-top";
import arrowUp from "../../images/Arrow-full-up.svg";

function Gallery() {
    const dispatch = useDispatch()
    const contextValue = useActiveFilters()
    const mountedComponent = useRef(false)
    const galleryRef = useRef(null)
    const isMobile = useMediaQuery({query: '(max-width: 800px)'});
    const screenWidth = useResize();
    const countryPoints = useSelector((store) => store.cardsFiltred.countryPoints)
    const companyPoints = useSelector((store) => store.cardsFiltred.companyPoints)
    const sectorPoints = useSelector((store) => store.cardsFiltred.sectorPoints)
    const categoryPoints = useSelector((store) => store.cardsFiltred.categoryPoints)
    const analysisPoints = useSelector((store) => store.cardsFiltred.analysisPoints)
    const subscriptionPoints = useSelector((store) => store.cardsFiltred.subscriptionPoints)
    const bondsPoints = useSelector((store) => store.cardsFiltred.bondsPoints)
    const changePortfolios = useSelector((store) => store.cardsFiltred.changePortfolios)
    const isLoading = useSelector((store) => store.preloader.isLoading)
    const orderingPoints = useSelector((store) => store.cardsFiltred.orderingPoints)

    const mixNewsAndAnalytics = (arr1, arr2) => {
        const mergedArray = [...arr1, ...arr2];
        mergedArray.sort((a, b) => new Date(b.pub_date) - new Date(a.pub_date));

        return mergedArray;
    }

    const mixPosts = mixNewsAndAnalytics(contextValue.filterCards, contextValue.filteredNews);

    const getCardWithFilters = useCallback(() => {
        contextValue.setAllGalleryCards(
            orderingPoints,
            categoryPoints,
            countryPoints,
            sectorPoints,
            companyPoints,
            analysisPoints,
            subscriptionPoints,
            bondsPoints,
            changePortfolios
        )
    }, [
        orderingPoints,
        categoryPoints,
        countryPoints,
        sectorPoints,
        companyPoints,
        analysisPoints,
        subscriptionPoints,
        bondsPoints,
        changePortfolios,
        contextValue.pageCount
    ])

    const loadMoreCards = useCallback(() => {
        if (contextValue.filterCards.length % 18 === 0 || contextValue.filterCards.length % 12 === 0) {
            contextValue.mounted.current = false
            dispatch(setIsLoading(true))
            contextValue.setPageCount((prev) => prev + 1)
        }
    }, [contextValue.pageCount, isLoading, contextValue.filterCards])

    const handleScroll = () => {
        contextValue.scrollPosition.current = window.scrollY
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - (isMobile ? 1200 : 800) && !isLoading) {
            loadMoreCards()
        }
    }

    useEffect(() => {
        if (contextValue.filterCards.length > 0) {
            window.addEventListener("scroll", handleScroll)
        }

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [isLoading, contextValue.filterCards])

    useEffect(() => {
        if (!contextValue.mounted.current && mountedComponent.current) {
            getCardWithFilters()
        }
    }, [contextValue.pageCount])

    useEffect(() => {
        if (!mountedComponent.current) {
            mountedComponent.current = true
        } else {
            contextValue.setFilterCards([])
            contextValue.setPageCount(1)
            dispatch(setIsLoading(true))
            getCardWithFilters()
        }

    }, [
        orderingPoints,
        categoryPoints,
        countryPoints,
        sectorPoints,
        companyPoints,
        analysisPoints,
        subscriptionPoints,
        bondsPoints,
        changePortfolios,
    ])

    useEffect(() => {
        if (contextValue.mounted.current) {
            window.scrollTo(0, parseInt(contextValue.scrollPosition.current, 10));
        }
        if (!contextValue.mounted.current) {
            if (contextValue.filterCards.length === 0) {
                dispatch(setIsLoading(true))
                if (!contextValue.hasUrl.current) {
                    getCardWithFilters()
                    contextValue.hasUrl.current = false
                }
            }
        }
        return () => {
            contextValue.mounted.current = true
        }
    }, [])

    return (
        <section className={styles.sectionDisable}>
            {isLoading && !contextValue.filterCards.length ? (
                <div className={styles.preloader}>
                    <Preloader/>
                </div>
            ) : (
                <div className={styles.gallery}>
                    <div className={`${styles.analytics} ${contextValue.filteredNews.length === 0 && styles.analyticsColumns}`} ref={galleryRef}>
                        {screenWidth > 800 ? (contextValue.filterCards.map((element, _, oneEl) => {
                                return (
                                    <div key={v1()}>{categoryPoints === '' || categoryPoints.includes('2') ?
                                        <Card  item={element} oneEl={oneEl}/>
                                        :
                                        ''
                                    }
                                    </div>)
                            })) :
                            (mixPosts.map((element, _, oneEl) => (
                                element.page_category[0].name === 'Аналитика' && (categoryPoints === '' || categoryPoints.includes('2')) ?
                                    <Card item={element} oneEl={oneEl} key={v1()}/>
                                    :
                                    element.page_category[0].name === 'Новости' && (categoryPoints === '' || categoryPoints.includes('1')) ?
                                    <NewsCard item={element} oneEl={oneEl} key={v1()}/> : ''
                            )))
                        }
                    </div>
                    {contextValue.filteredNews.length > 0 && (categoryPoints === '' || categoryPoints.includes('1')) && <div className={styles.newsBlock}>
                        <div className={styles.news}>
                            {contextValue.filteredNews.map(newsElement => {
                                return (
                                    <NewsElement key={newsElement.id + Math.random()}
                                                 title={newsElement.name}
                                                 company={newsElement.company_tag[0]?.ticker}
                                                 date={newsElement.pub_date_formated}
                                                 slug={newsElement.slug}
                                    />
                                )
                            })}

                            {contextValue.isNextPageForNews && <button className={styles.moreNewsBtn} onClick={loadMoreCards}>
                                {isLoading ? 'Загрузка...' : 'Больше новостей'}
                            </button>}
                        </div>

                        {contextValue.filterCards.length === 0 && (
                            <div className={styles.caution}>
                                Ничего не найдено <br/> Смените параметры фильтрации
                            </div>
                        )}
                    </div>}
                </div>
            )}
            <ScrollToTopButton />
        </section>
    )
}

export default Gallery