import styles from './how-we-can-help.module.css';
import Deal from "../../images/how-we-can-help/deal.svg";
import Chart from "../../images/how-we-can-help/chart.svg";
import Education from "../../images/how-we-can-help/education.svg";
import WalletCheck from "../../images/how-we-can-help/wallet-check.svg";
import clsx from "classnames";

export const HowWeCanHelp = () => {
  return (
    <div className={clsx(styles.howWeCanHelp, "content")}>
      <h2 className={styles.title}>Чем мы можем помочь?</h2>
      <ul className={styles.benefitList}>
        <li className={styles.benefit}>
          <img src={Education} alt=""/>
          <h3 className={styles.benefitTitle}>Обучение</h3>
          <p className={styles.benefitDescription}>Полноценный обучающий&nbsp;&nbsp;курс</p>
        </li>
        <li className={styles.benefit}>
          <img src={Chart} alt=""/>
          <h3 className={styles.benefitTitle}>Инструменты</h3>
          <p className={styles.benefitDescription}>Аналитика и разборы компаний</p>
        </li>
        <li className={styles.benefit}>
          <img src={Deal} alt=""/>
          <h3 className={styles.benefitTitle}>Время</h3>
          <p className={styles.benefitDescription}>Сервис с готовыми сделками</p>
        </li>
        <li className={styles.benefit}>
          <img src={WalletCheck} alt=""/>
          <h3 className={styles.benefitTitle}>Результат</h3>
          <p className={styles.benefitDescription}>Портфели, обгоняющие индекс</p>
        </li>
      </ul>
    </div>
  );
};
