import styles from "./why-we.module.css";
import Chart from "../../images/why-we/chart.svg";
import Chess from "../../images/why-we/chess.svg";
import IndexCompare from "../../images/why-we/index-compare.png";
import LineChart from "../../images/why-we/line-chart.svg";
import clsx from "classnames";

export const WhyWe = () => {
  return (
    <div className={styles.whyWeWrapper}>
      <div className={clsx(styles.whyWe, "content")}>
        <div className={styles.gradient}/>
        <p className={styles.whyWeText}>ПОЧЕМУ МЫ?</p>
        <h2 className={styles.title}>Мы предлагаем индивидуальный подход и помощь</h2>
        <p className={styles.description}>Наши эксперты ответят на ваши вопросы</p>
        <div className={styles.benefits}>
          <div className={clsx(styles.chess, styles.benefit)}>
            <img className={styles.chessImage} src={Chess} alt="chess"/>
          </div>
          <div className={clsx(styles.expertTeam, styles.benefit)}>
            <img className={styles.expertTeamChart} src={Chart} alt=""/>
            <h3 className={styles.expertTeamTitle}>Команда экпертов</h3>
            <p className={styles.expertTeamDescription}>
              Боллее 10 лет на рынке, опыт работы в инвестиционных банках, CFA
            </p>
          </div>
          <div className={clsx(styles.happyClients, styles.benefit)}>
            <p className={styles.happyClientsPercent}>96%</p>
            <p className={styles.happyClientsDescription}>Клиентов остаются после первого месяца</p>
          </div>
          <div className={clsx(styles.beyondIndex, styles.benefit)}>
            <p className={styles.beyoundIndexCount}>7 из 8</p>
            <p className={styles.beyoundIndexDescription}>Портфелей опережают индекс</p>
            <img className={styles.beyondIndexLineChart} src={LineChart} alt=""/>
          </div>
        </div>
        <p className={styles.ourPortfolioText}>НАШ ПОРТФЕЛЬ</p>
        <img className={styles.indexCompare} width={1080} height={589} src={IndexCompare} alt="index compare"/>
      </div>
    </div>
  );
};
