import styles from "./payback.module.css";
import clsx from "classnames";
import Portfolio from "../../images/payback/Portfolio.svg";

export const Payback = () => {
  return (
    <div className={styles.paypackWrapper}>
      <div className={clsx(styles.content, "content")}>
        <div className={styles.header}>
          <h2 className={styles.title}>От какой суммы окупается подписка?</h2>
          <p className={styles.description}>Наши портфели опережают индекс в среднем на 23,5% в год</p>
        </div>
        <div className={styles.portfolios}>
          <div className={styles.portfolio}>
            <div className={styles.portfolioHeader}>
              <h3 className={styles.portfolioTitle}>Портфель 1 млн ₽</h3>
              <img className={styles.portfolioImg} src={Portfolio} alt=""/>
            </div>
            <p className={styles.profitLabel}>Выгода</p>
            <p className={styles.profitValue}>235 тыс ₽</p>
          </div>
          <div className={clsx(styles.portfolio, styles.portfolioBottom)}>
            <div className={styles.portfolioHeader}>
              <h3 className={styles.portfolioTitle}>Портфель 200 тыс ₽</h3>
              <img className={styles.portfolioImg} src={Portfolio} alt=""/>
            </div>
            <p className={styles.profitLabel}>Выгода</p>
            <p className={styles.profitValue}>47 тыс ₽</p>
          </div>
        </div>
        <div className={styles.tickersWrapper}>
          <div className={styles.tickers}>
            <div className={clsx(styles.ticker, styles.tickerTop)}>
              <p className={styles.tickerName}>MTLR</p>
              <p className={styles.tickerChange}>–64,07%</p>
            </div>
            <div className={clsx(styles.ticker, styles.tickerBottom)}>
              <p className={styles.tickerName}>SGZH</p>
              <p className={styles.tickerChange}>–75,96%</p>
            </div>
          </div>
        </div>
        <ul className={styles.benefits}>
          <li className={styles.benefit}>Всего за 1200 Р вы получите аналитику, которая сбережет вам в 100 раз больше
            средств
          </li>
          <li className={styles.benefit}>Гарантированный возврат в течение 30 дней</li>
          <li className={styles.benefit}>Инвесторы теряют десятки и сотни тысяч из-за неудачных сделок</li>
        </ul>
      </div>
    </div>
  );
};
