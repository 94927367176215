import React, {useEffect, useRef, useState} from "react";
import {Popover} from "antd";
import checkmark from "../../../../images/check-mark.svg";
import question from "../../../../images/question-icon.svg";
import styles from "./tariffs-item-benefit.module.css";
import {useResize} from "../../../../services/hooks/useResize";

export const TariffsItemBenefit = ({benefit, index, activePopover, setActivePopover}) => {
    // const [isShowTooltip, setIsShowTooltip] = useState(false)
    const popoverRef = useRef(null);
    const screenWidth = useResize();
    const [touchStartX, setTouchStartX] = useState(0);

    const handleClickOutside = (event) => {
        // Если клик произошел вне popover, то закрываем его
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setActivePopover(null); // Закрываем, если клик вне Popover
        }
    };
    const handleTouchStart = (event) => {
        setTouchStartX(event.touches[0].clientX);
    };

    const handleTouchEnd = (event) => {
        const touchEndX = event.changedTouches[0].clientX;
        const swipeDistance = Math.abs(touchStartX - touchEndX);

        // Закрываем Popover, если свайп произошел на расстояние больше, чем 50 пикселей
        if (swipeDistance > 15) {
            setActivePopover(null);
        }
    };

    useEffect(() => {
        // Добавляем обработчики для кликов и свайпов
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleTouchStart);
        document.addEventListener("touchend", handleTouchEnd);

        return () => {
            // Убираем обработчики при размонтировании компонента
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleTouchStart);
            document.removeEventListener("touchend", handleTouchEnd);
        };
    }, [touchStartX]);


    const handleVisibleChange = (isVisible) => {
        if (isVisible) {
            setActivePopover(index); // Устанавливаем активный Popover
        } else {
            setActivePopover(null); // Закрываем Popover при клике по нему же
        }
    };
//
    return (
        <div className={styles.benefit} ref={popoverRef}>
            <p className={styles.benefitInfoWrapper}>
                <img src={checkmark} alt="checkmark" width={screenWidth > 500 ? 20 : 15}/>
                <span>{benefit.label}</span>
            </p>
            {screenWidth > 1500 ?
                <Popover placement="top" content={<p className={styles.tooltip}>{benefit.tooltip}</p>} color="#ccc"
                >
                    <img src={question} alt="question" width={screenWidth > 500 ? 20 : 15}/>
                </Popover>
                :
                <Popover placement="top" content={<p className={styles.tooltip}>{benefit.tooltip}</p>} color="#ccc"
                         style={{padding: '10px'}}
                         trigger="click"
                         visible={activePopover === index} // Popover видим только если он активный
                         onVisibleChange={handleVisibleChange}
                >
                    <img src={question} alt="question" width={screenWidth > 500 ? 20 : 15}/>
                </Popover>}
            {/*      <img src={question} alt="question" width={24}
           onMouseEnter={() => setIsShowTooltip(true)}
           onMouseLeave={() => setIsShowTooltip(false)}
      />
      {isShowTooltip &&
        <span className={styles.tooltipText}>{benefit.tooltip}</span>}*/}
        </div>
    );
};