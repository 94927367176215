import styles from './sub-box-profile.module.css';

const SubscriptionBox = ({
  subInfo,
  setShowModal,
  setSubSelect,
  setSubNameSelect,
  setShowNoSubsInfo,
}) => {
  return (
    <>
      <div className={styles.containerWrapper}>

          <div className={styles.title}>{subInfo.name}</div>

          <div className={styles.dataArrayWrapper}>
            {subInfo.tags.map((el) => {
              return (
                <div className={styles.dataPoint} key={el}>
                  {el}
                </div>
              );
            })}
          </div>

        <div className={styles.bottomContainer}>
          <div
            onClick={() => {
              setShowModal(true);
              setSubSelect(subInfo.slug);
              setSubNameSelect(subInfo.name);
            }}
            className={styles.buttonWrapper}>
            <div className={styles.buttonTxt}>Подключить от {subInfo.payment_amount} ₽ /мес</div>
          </div>

          <div
            className={styles.buttonWrapper}
            onClick={() => {
              setShowNoSubsInfo(true);
              setSubNameSelect(subInfo.name);
            }}>
            <div className={styles.buttonTxt}>Подробнее о подписке</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionBox;
