  import {useEffect, useState} from "react";
import {getMarketMultipliersMap} from "../../../utils/api";
import {transformMultipliersData} from "../utilsForMarketMap";

export function useTreeMapMultipliersData({isInitialized}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)

  useEffect(() => {
    if (isInitialized) {
      setLoading(true)

      getMarketMultipliersMap()
        .then(json => {
          setData(transformMultipliersData(json.sectors));
        })
        .catch(e => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isInitialized]);

  return {data, loading, loadingError: error};
}