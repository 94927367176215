import styles from "./news-element.module.css";
import clsx from "classnames";
import {NavLink} from "react-router-dom";
import {useTheme} from "../../services/hooks/useTheme";

export function NewsElement({title, company, date, slug}) {

    const companyClass = clsx({
        [styles.company]: true,
        [styles.hidden]: !company
    });

    const dotClass = clsx({
        [styles.dot]: true,
        [styles.hidden]: !company
    });

    return (
        <div className={styles.newsElement}>
            <NavLink to={`/analytics-and-news/${slug}`} className={styles.link}>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.companyAndDate}>
                    <p className={companyClass}>#{company}</p>
                    <span className={dotClass}>•</span>
                    <span className={styles.date}>{date}</span>
                </div>
            </NavLink>
        </div>
    )
}
