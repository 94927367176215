import React from 'react';
import clsx from 'clsx';
import styles from './button.module.css';

/**
 * Компонент Button, который принимает все стандартные пропсы HTML элемента button.
 *
 * @param {Object} props - Пропсы для компонента Button.
 * @param {React.ReactNode} props.children - Контент внутри кнопки.
 * @param {string} [props.type='button'] - Тип кнопки (button, submit, reset). По умолчанию 'button'.
 * @param {boolean} [props.disabled=false] - Отключена ли кнопка. По умолчанию false.
 * @param {function} [props.onClick] - Функция, вызываемая при клике на кнопку.
 * @param {string} [props.className] - Дополнительный CSS-класс для стилизации кнопки.
 *
 * @returns {JSX.Element} JSX-элемент кнопки.
 */
export const Button = ({ children, type = 'button', disabled = false, onClick, className, ...rest }) => {
    return (
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={clsx(styles.button, className)}
        {...rest}
      >
          {children}
      </button>
    );
};
