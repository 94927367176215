import {forwardRef} from "react";
import arrowDownGray from "../../../../images/arrow-down-sign-to-navigate-svg.svg";
import {FilterSectionMarketMap} from "../filter-section/filter-section-market-map";
import styles from "./filterList.module.css";

export const FilterList = forwardRef(({
                                        loading,
                                        singleOpener,
                                        valueForSingleOpener,
                                        selectedValue,
                                        activeMenu,
                                        setActiveMenu,
                                        setSelectedValue,
                                        categoryPoints,
                                        typeValue,
                                        buttonName
                                      }, ref) => {
  const selectedValueText = selectedValue.name.replace("(P/B для фин. сектора)", "");

  return (
    <div className={styles.sectionFilterWrapper} ref={ref}>
      <div onClick={() => {
        !loading && singleOpener(valueForSingleOpener)
      }}>
        <div className={`${styles.selectBoxWrapper} ${loading && styles.disabled}`}>
          <div className={styles.txtFilter}>
            <span>{buttonName}:</span> <span>{selectedValueText}</span>
          </div>
          <img
            alt="arrow down"
            src={arrowDownGray}
            className={styles.arrowDownGray}
          />
        </div>
      </div>

      <div className={styles.filtersSection}>
        {activeMenu && (
          <FilterSectionMarketMap
            setActiveTypeMenu={setActiveMenu}
            setSelectedRange={setSelectedValue}
            categoryPoints={categoryPoints}
            type={typeValue}
          />
        )}
      </div>
    </div>
  );
});
