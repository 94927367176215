import {useEffect, useState} from "react";
import arrowUp from '../../images/Arrow-full-up.svg'
import styles from "./scroll-to-top.module.css";

export const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const toggleVisibility = () => {
        if (window.scrollY > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div className={styles.backBtnWrapper}>
            {isVisible && (
                <button className={styles.scrollToTopBtn} onClick={scrollToTop}>
                    <img src={arrowUp} alt="arrow up"/>
                </button>
            )}
        </div>
    );
};