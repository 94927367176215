import React, {useEffect, useRef, useState} from "react";
import glossary from '../../../images/glossary.png'
import {GlossaryItem} from "./glossary-item/glossary-item";
import {getGlossary} from "../../../utils/api";
import Preloader from "../../preloader/preloader";
import {BreadcrumbNavigation} from "../../breadcrumb-navigation/breadcrumbsNavigation";
import {ScrollToTopButton} from "../../scroll-to-top/scroll-to-top";
import styles from './glossary.module.css'

export const Glossary = () => {
    const refs = useRef({});
    const headBlockWrapperRef = useRef(null);
    const glossaryRef = useRef(null);
    const footerRef = useRef(null); // Добавим ссылку на Footer
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const russianKeys = data && Object.keys(data).filter((key) => /^[А-ЯЁа-яё]+$/.test(key));
    const englishKeys = data && Object.keys(data).filter((key) => /^[A-Za-z]+$/.test(key));

    const sortObjectByKeys = (obj) => {
        const sortedKeys = Object.keys(obj).sort((a, b) => a.localeCompare(b, 'ru'));
        const sortedObj = {};
        sortedKeys.forEach(key => {
            sortedObj[key] = obj[key];
        });
        return sortedObj;
    }

    const handleScroll = (key) => {
        if (refs.current[key]) {
            refs.current[key].scrollIntoView({behavior: 'smooth'});
        }
    };

    useEffect(() => {
        setLoading(true)
        getGlossary().then(async (res) => {
            if (res.ok) {
                setLoading(false)
                const data = await res.json()
                const result = sortObjectByKeys(data)
                setData(result)
            } else {
                setLoading(false)
                console.log(res)
            }
        })
    }, []);

    return (
        <div className={styles.glossary} ref={glossaryRef}>
            <div className={styles.headBlockWrapper} ref={headBlockWrapperRef}>
                <div className={styles.headBlock}>
                    <div>
                        <BreadcrumbNavigation links={[{label: "Главная", url: "/"}]} forBg={"dark"}/>
                        <h2 className={styles.title}>Глоссарий</h2>
                    </div>
                    <div className={styles.glossaryImgWrapper}>
                        <img src={glossary} alt="Глоссарий" width={310} className={styles.glossaryImg}/>
                    </div>
                </div>
            </div>

            {loading ? <div className={styles.preloaderContainer}>
                    <Preloader/>
                </div>
                :
                <div className={styles.contentWrapper}>
                    <div className={styles.content}>

                        <div className={styles.itemsContainer}>
                            {data && Object.keys(data).map((key) => (
                                <div
                                    key={key}
                                    className={styles.itemsContainer}
                                    ref={(el) => (refs.current[key] = el)}
                                >
                                    {data[key].map((el, index) => (
                                        <GlossaryItem key={index} title={el.name} description={el.description}/>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className={styles.anchorsContainer}>
                            <div className={styles.anchor}>
                                {data && russianKeys.map((key) => {
                                        return <button
                                            type={'button'}
                                            key={key}
                                            className={styles.anchorItem}
                                            onClick={() => handleScroll(key)}
                                        >
                                            {key}
                                        </button>
                                    }
                                )}
                            </div>
                            <div className={styles.anchor}>
                                {data && englishKeys.map((key) => {
                                        return <button
                                            type={'button'}
                                            key={key}
                                            className={styles.anchorItem}
                                            onClick={() => handleScroll(key)}
                                        >
                                            {key}
                                        </button>
                                    }
                                )}
                            </div>
                            <ScrollToTopButton />
                        </div>
                    </div>
                </div>}
            <div ref={footerRef}></div>
        </div>
    );
};







