import {useEffect, useState} from "react";
import {getMapMarket} from "../../../utils/api";
import {transformData} from "../utilsForMarketMap";

export function useTreeMapData({isInitialized, selectedIndex, selectedRange, selectedSector}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isInitialized) {
      setLoading(true)
      const index = selectedIndex.id === 'iMOEX' || selectedIndex.id === 'iMOEX+IE' ? 'all' : selectedIndex.id
      const iMOEX = selectedIndex.id === 'iMOEX' || selectedIndex.id === 'iMOEX+IE'
      const iMOEXplusIE = selectedIndex.id === 'iMOEX+IE'

      setLoading(true);
      getMapMarket(selectedRange.id, iMOEXplusIE, iMOEX, index, selectedSector.id)
        .then(data => {
          setData(transformData(data.sectors))
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        })
    }

  }, [selectedRange, selectedSector, selectedIndex, isInitialized]);

  return {data, loading, loadingError: error};
}