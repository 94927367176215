import {useEffect, useState} from "react";
import MainSectionInfo from '../main-section-info/main-section-info';
import SEO from '../../utils/SEO';
import SeoScriptMain from './seo-script';
import TenDaysPromocode from '../promocode-10days/promocode-tenDays';
import WindowPaySub from "../app-main-profile/window-pay-sub/window-pay-sub";
import ExteriorNotice from "../app-main-profile/popup-sections/pay-sub/exterior-notice";
import {Tariffs} from "../tariffs/tariffs";
import styles from './app-main.module.css';
import {LowIncome} from "../low-income/low-income";
import {HowWeCanHelp} from "../how-we-can-help/how-we-can-help";
import {WhyWe} from "../why-we/why-we";
import {Faq} from "../faq/faq";
import {Benefits} from "../benefits/benefits";
import {BeyondIndex} from "../beyond-index/beyond-index";
import {Payback} from "../payback/payback";

function AppMain() {
    const currentLink = new URL(window.location.href);
    const [showModal, setShowModal] = useState(!!currentLink.searchParams.get("subSelect"))
    const [showExteriorNotice, setShowExteriorNotice] = useState(false);
    const [subNameSelect, setSubNameSelect] = useState(currentLink.searchParams.get("subNameSelect"));
    const [subSelect, setSubSelect] = useState(currentLink.searchParams.get("subSelect"));
    const [defaultOptionMonth, setDefaultOptionMonth] = useState(+(currentLink.searchParams.get("optionMonth") || 0));

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete("subSelect");
        currentUrl.searchParams.delete("subNameSelect");
        currentUrl.searchParams.delete("optionMonth");
        window.history.replaceState(null, null, currentUrl.toString());
    }, [])
//
    return (
        <main>
            <div className={styles.info}>
                <SEO
                    title="Invest Era: фундаментальная аналитика, инвест идеи, обучение"
                    description="Повышаем доходность на фондовом рынке, используя фундаментальный анализ. Разборы компаний, инвест идеи, обучение."
                    ogTitle="Invest Era: фундаментальная аналитика, инвест идеи, обучение"
                    ogDescription="Повышаем доходность на фондовом рынке, используя фундаментальный анализ. Разборы компаний, инвест идеи, обучение."
                    script={SeoScriptMain}
                />
                <MainSectionInfo/>
            </div>
            <div className={styles.box}>
                {showModal && (
                    <WindowPaySub
                        setShowExteriorNotice={setShowExteriorNotice}
                        subNameSelect={subNameSelect}
                        subSelect={subSelect}
                        setShowModal={setShowModal}
                        defaultOptionMonth={defaultOptionMonth}
                    />
                )}
                {showExteriorNotice && <ExteriorNotice setShowExteriorNotice={setShowExteriorNotice} />}
                <TenDaysPromocode/>
                <LowIncome />
                <HowWeCanHelp />
                <WhyWe />
                <Benefits />
                <BeyondIndex />
                <Tariffs setShowModal={setShowModal}
                         setSubNameSelect={setSubNameSelect}
                         setSubSelect={setSubSelect}
                         setDefaultOptionMonth={setDefaultOptionMonth}
                />
                <Payback />
                <Faq />
            </div>
        </main>
    );
}

export default AppMain;
