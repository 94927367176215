import styles from "./main-section-info.module.css"
import {useMediaQuery} from "react-responsive"
import usCircle from "../../images/us-circle.svg";
import russiaCircle from "../../images/russia-circle.svg";
import {Button} from "../button/button";
import clsx from "clsx";

const scrollToAnchor = (anchorName) => {
  const anchorElement = document.getElementById(anchorName)
  if (anchorElement) {
    anchorElement.scrollIntoView({behavior: "smooth", block: "start"})
  }
}

const TopMainTitle = () => {
  const isMobile = useMediaQuery({query: "(max-width: 800px)"})

  return (
    <section className={styles.section}>
      <div className={styles.mainContentWrapper}>
        <div className={styles.gradient}/>
        <div className={styles.descriptionWrapper}>
          <div className={styles.mainTitle}>
            <span className={styles.companyName}>Invest Era</span><br/>
            Информация, которая увеличивает ваш доход
          </div>
          <div className={styles.secondTitle}>
            Invest Era — аналитический сервис для&nbsp;инвесторов
          </div>
          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>
              Оперативно анализируем ключевые события на&nbsp;рынках России, США и&nbsp;Китая, предоставляя обоснованные
              рекомендации
            </div>
            <div className={styles.subTitle}>
              Поможем увеличить доходность вашего портфеля с&nbsp;помощью глубокого анализа и&nbsp;инвестиционных идей
            </div>
            <div className={styles.subTitle}>
              Научим эффективно зарабатывать на&nbsp;фондовом рынке
            </div>
          </div>
          {isMobile && <PortfolioList/>}
          <Button className={clsx({
            [styles.ourProductsButton]: true,
            [styles.ourProductsButtonMobile]: isMobile
          })} onClick={() => scrollToAnchor("tariffs")}>
            Наши продукты
          </Button>
        </div>
        {!isMobile && <PortfolioList/>}
      </div>
    </section>
  );

  function PortfolioList() {
    return (
      <div className={clsx({
        [styles.portfolioList]: true,
        [styles.portfolioListMobile]: isMobile,
      })}>
        <div className={clsx(styles.portolio, styles.russiaPortfolio)}>
          <p className={styles.portfolioText}>Портфель</p>
          <p className={styles.portfolioTitle}>РФ Долгосрочный</p>
          <p className={styles.portfolioDate}>июнь 2022 - cентябрь 2024</p>
          <p className={styles.portfolioIncome}>Доходность 70,5%</p>
          <img className={styles.portfolioFlag} src={russiaCircle} alt="русский флаг"/>
        </div>
        <div className={clsx(styles.portolio, styles.usaPortfolio)}>
          <p className={styles.portfolioText}>Портфель</p>
          <p className={styles.portfolioTitle}>США Дивидендный</p>
          <p className={styles.portfolioDate}>июнь 2022 - сентябрь 2024</p>
          <p className={styles.portfolioIncome}>Доходность 29,5%</p>
          <img className={styles.portfolioFlag} src={usCircle} alt="флаг США"/>
        </div>
      </div>
    )
  }

}
export default TopMainTitle
