export const selectIndexOptions = [
  {value: 'peIndex', label: 'P/E Индекса Мосбиржи'},
  {value: 'evEbitdaIndex', label: 'EV/EBITDA Индекса Мосбиржи'},
  {value: 'peSecondTier', label: 'P/E 2-3 эшелон'},
  {value: 'evEbitdaSecondTier', label: 'EV/EBITDA 2-3 эшелон'},
  {value: 'divYieldIndex', label: 'Дивдоходность IMOEX'},
  {value: 'divYieldSecondTier', label: 'Дивдоходность 2-3 эшелон'},
];

export const selectPeriodOptions = [
  {id: 1, name: '1 нед.', count: 7},
  {id: 2, name: '1 мес.', count: 30},
  {id: 3, name: '3 мес.', count: 90},
  {id: 4, name: '6 мес.', count: 180},
  {id: 5, name: '1 год', count: 365},
  {id: 7, name: '5 лет', count: 365 * 5},
  {id: 6, name: 'Все', count: Infinity},
];
