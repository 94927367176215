import styles from "./easy-start-product.module.css"
import { useMediaQuery } from "react-responsive"
import UsaFlag from "../../../../../../../images/product-circle-flag-usa.svg"
import ChainFlag from "../../../../../../../images/product-circle-flag-china.svg"
import EarthFlag from "../../../../../../../images/product-circle-flag-earth.svg"
const FirstDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const isIpad = useMediaQuery({ query: "(max-width: 1280px)" })

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.title}>
            Модельные портфели, обгонящие индекс
          </div>
          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>Точки входа и целевые цены</div>
            <div className={styles.subTitle}>
              Прогнозы по мультипликаторам и дивидендам
            </div>
          </div>

          <div className={styles.flagBoxes}>
            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={UsaFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>США оптимальный</div>
                  <div className={styles.contextSubTitle}>
                    Сбалансированный долгосрочный портфель
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                Обгоняет индекс {!isIpad ? <br/> : null} за январь 2023 — сентябрь 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>0,4%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={UsaFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>США дивидендный</div>
                  <div className={styles.contextSubTitle}>
                    Целевая дивидендная доходность более 4%
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                Обгоняет индекс {!isIpad ? <br/> : null} за июнь 2022 — сентябрь 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>13,7%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={ChainFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>Китай долгосрочный</div>
                  <div className={styles.contextSubTitle}>
                    Покупаем китайские акции на многолетних минимумах
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                Обгоняет индекс {!isIpad ? <br/> : null} за ноябрь 2022 — сентябрь 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>11,1%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirstDescription
