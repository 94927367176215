import React from 'react';

import Artem from '../../images/Artem.png';
import Star from '../../images/tuiIconStarLarge.svg';
import OOO from '../../images/ООО.svg';
import Company from '../../images/tuiIconCompanyLarge.svg';
import MarksLeft from '../../images/marks-left.svg';
import MarksRight from '../../images/marks-right.svg';
import Aleksey from '../../images/Aleksey.png';
import Artem1 from '../../images/Artem1.png';
import Anatoliy from '../../images/Anatoliy.png';
import Irina from '../../images/Irina.png';
import Dmitriy from '../../images/Dmitriy.png';
import DmitryZ from "../../images/dmitryZ.jpg";

import styles from './our-team.module.css';
import clsx from "classnames";

const analyticsTeam = [
  {
    name: "Алексей Наумов",
    description:
      "Более 10 лет на руководящих должностях в банках и фондах. Управлял более чем 10 b$.\n" +
      "Кандидат экономических наук, CFA",
    image: Aleksey,
  },
  {
    name: "Анатолий Мухин",
    description:
      "Работал в ВТБ Капитал на ведущих позициях в роли аналитика и трейдера. Выпускник РЭШ",
    image: Anatoliy,
  },
  {
    name: "Денис Зайцев",
    description: "Свыше 20 лет практического опыта успешной работы на фондовом и долговом рынке. Основная специализация - фундаментальный инвестиционный анализ иностранных компаний. Высшее экономическое образование.",
    image: DmitryZ
  },
  {
    name: "Дмитрий Толмачев",
    description:
      "Работал в ЦБ РФ, Почта Банк, Тинькофф, Сбербанк и Дом РФ финансовым аналитиком в отделах финансового моделирования. Выпускник финансового университета",
    image: Dmitriy,
  }
];

const supportTeam = [
  {
    name: "Ирина Трусова",
    description: "Отвечает за техподдержку и сопровождение клиентов.",
    image: Irina,
  },
];

const itTeam = [
  {
    name: "Артем Приказчиков",
    description: "Руководитель IT отдела. Отвечает за IT и продуктовое направление.",
    image: Artem1,
  },
];


const OurTeam = () => {

  return (
    <div className={styles.ourTeam}>
      <h2 className={styles.title}>Наша команда</h2>
      <div className={styles.wrapperProfile}>
        <div className={styles.profile}>
          <div className={styles.description}>
            <div className={styles.image}>
              <img src={Artem} alt="photo"/>
            </div>
            <div className={styles.name}>
              Артём Николаев
              <span className={styles.position}>CEO</span>
            </div>
          </div>
          <ul className={styles.experience}>
            <li className={styles.list}>
              {' '}
              <img src={Star} alt="star"/>
              Основатель Invest Era
            </li>
            <li className={styles.list}>
              {' '}
              <img src={OOO} alt="OOO"/>
              Руководитель аналитического отдела
            </li>
            <li className={styles.list}>
              {' '}
              <img src={Company} alt="company"/>
              Консультирует более 30 клиентов <br/> с портфелями 100+ млн рублей
            </li>
          </ul>
        </div>
        <div className={styles.comment}>
          <div className={styles.text}>
            <img className={styles.marksLeft} src={MarksLeft} alt="marks"/>
            Наша Миссия – помогать инвесторам достигать своих финансовых целей и улучшать свою жизнь
            <img className={styles.marksRight} src={MarksRight} alt="marks"/>
          </div>
        </div>
      </div>
      <h3 className={clsx(styles.header3, styles.firstHeader3)}>Команда аналитики</h3>
      <div className={styles.wrapperTeam}>
        {analyticsTeam.map(teamItem => (
          <TeamItem position="Инвестиционный аналитик"
                    name={teamItem.name}
                    description={teamItem.description}
                    img={teamItem.image}/>
        ))}
      </div>
      <div className={styles.supportAndIt}>
        <div className={styles.supportTeamBlock}>
          <h3 className={styles.header3}>Клиентский сервис</h3>
          {supportTeam.map(teamItem => (
            <TeamItem position="Руководитель клиентского сервиса"
                      name={teamItem.name}
                      description={teamItem.description}
                      img={teamItem.image}/>
          ))}
        </div>
        <div className={styles.itTeamBlock}>
          <h3 className={styles.header3}>Информационные технологии</h3>
          {itTeam.map(teamItem => (
            <TeamItem position="Руководитель IT отдела"
                      name={teamItem.name}
                      description={teamItem.description}
                      img={teamItem.image}/>
          ))}
        </div>
      </div>
    </div>
  );
};

const TeamItem = ({name, position, description, img}) => (
  <div className={styles.teamItem}>
    <div className={styles.teamInfo}>
      <div className={styles.itemName}>{name}</div>
      <div className={styles.itemPosition}>{position}</div>
      <div className={styles.itemExperience}>{description}</div>
    </div>
    <div className={styles.teamImg}>
      <img src={img} alt="photo"/>
    </div>
  </div>
)

export default OurTeam;
