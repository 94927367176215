import styles from './low-income.module.css';

import Hour from "../../images/low-income/Hour.svg";
import Money from "../../images/low-income/Money.svg";
import Portfolio from "../../images/low-income/Portfolio.svg";
import clsx from "classnames";

export function LowIncome() {
  return (
    <div className={styles.lowIncomeWrapper}>
      <div className={clsx(styles.lowIncome, "content")}>
        <div className={styles.textContent}>
          <p className={styles.lowIncomeText}>НИЗКАЯ ДОХОДНОСТЬ?</p>
          <h2 className={styles.title}>Большинство инвесторов получают доходность ниже индекса</h2>
          <p className={styles.description}>По исследованию Тинькофф инвестиции средняя доходность частного инвестора на
            30% хуже индекса</p>
        </div>
        <div className={styles.graphicContent}>
          <div className={styles.graphicContentGradient}></div>
          <div className={styles.graphicContentImages}>
            <div className={styles.graphicContentLine}></div>
            <div className={styles.graphicContentImage}>
              <img className={styles.translate1px} src={Hour} alt=""/>
            </div>
            <div className={styles.graphicContentImage}>
              <img src={Portfolio} alt=""/>
            </div>
            <div className={styles.graphicContentImage}>
              <img src={Money} alt=""/>
            </div>
          </div>
          <p className={clsx(styles.graphicContentText, styles.graphicContentText1)}>Не хватает времени?</p>
          <p className={clsx(styles.graphicContentText, styles.graphicContentText2)}>Нет стратегии?</p>
          <p className={clsx(styles.graphicContentText, styles.graphicContentText3)}>Низкая доходность?</p>
          <p className={clsx(styles.graphicContentText, styles.graphicContentText4)}>Хаос в портфеле?</p>
          <p className={clsx(styles.graphicContentText, styles.graphicContentText5)}>Не получается <br/> разобраться?</p>
        </div>
      </div>
    </div>
  )
}