import React, {useEffect, useMemo, useState} from "react";
import {ResponsiveContainer, Tooltip, Treemap} from "recharts";
import {formatNumberValue, heightForMobileTreemap, heightForTreemap} from "../utilsForMarketMap";
import Preloader from "../../preloader/preloader";
import {useTheme} from "../../../services/hooks/useTheme";
import {useResize} from "../../../services/hooks/useResize";
import styles from "./treemapMultipliers.module.css";
import {FullScreenIcon} from "../../../images/components/full-screen-icon";
import {useZoom} from "../useZoom";
import {useTreeMapMultipliersData} from "./useTreeMapMultipliersData";
import {FiltersForMultipliersMap} from "../filters/filters-for-multipliers-map";
import {CustomMultipliersTooltip} from "../custom-tooltip/custom-multipliers-tooltip";
import {CustomizedMultipliersContent} from "../customized-content/customized-multipliers-content";
import {useNavigate} from "react-router-dom";

const filtersMultiplier = [
  {id: "pe_2024", name: "FWD P/E 2024"},
  {id: "ebitda_2024", name: "FWD EV/EBITDA 2024\n (P/B для фин. сектора)"},
  {id: "dividends_2024", name: "Див. доходность 2024"},
  {id: "free_float", name: "Free-float"},
  {id: "net_debt", name: "Чистый долг"},
  {id: "net_debt_ebitda_2024", name: "NetDebt/EBITDA 2024"},
  {id: "profit_growth_2024_2023", name: "Рост прибыли 2024 к 2023"},
  {id: "ebitda_growth_2024_2023", name: "Рост EBITDA 2024 к 2023"},
  {id: "historical_pe", name: "Среднеисторический P/E"},
  {id: "historical_ev_ebitda", name: "Среднеисторический EV/EBITDA\n (P/B для фин. сектора)"},
  {id: "discount_to_avg_ev_ebitda_2024", name: "Дисконт к среднеисторическому EV/EBITDA\n (P/B для фин. сектора)"},
  {id: "discount_to_avg_pe_2024", name: "Дисконт к среднеисторическому P/E"}
];

const filtersIndex = [
  {id: 'iMOEX', name: 'iMOEX'},
  {id: 'iMOEX+IE', name: 'iMOEX + выбор Invest Era'},
  {id: '1%2C2', name: '1-2 эшелоны'},
  {id: '3', name: '3 эшелон'},
  {id: 'all', name: 'все'},
];
const filtersSector = [
  {id: 'all', name: 'все'},
  {id: 'Энергетический', name: 'энергетический'},
  {id: 'Финансовый', name: 'финансовый'},
  {id: 'Добывающий', name: 'добывающий'},
  {id: 'IT', name: 'it'},
  {id: 'Коммуникации и развлечения', name: 'коммуникации и развлечения'},
  {id: 'REIT', name: 'reit'},
  {id: 'Потребительский защитный', name: 'потребительский защитный'},
  {id: 'Коммунальный', name: 'коммунальный'},
  {id: 'Здравоохранение', name: 'здравоохранение'},
  {id: 'Промышленный', name: 'промышленный'},
  {id: 'Потребительский цикличный', name: 'потребительский цикличный'},
];


export const TreemapMultipliersComponent = ({isFullscreen, setIsFullscreen}) => {
  const {theme} = useTheme();
  const screenWidth = useResize();
  const navigate = useNavigate();

  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedMultiplier, setSelectedMultiplier] = useState(filtersMultiplier[0]);
  const [selectedIndex, setSelectedIndex] = useState(filtersIndex[0]);
  const [selectedSector, setSelectedSector] = useState(filtersSector[0]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams.entries()) {
      if (key === 'sector') {
        paramsObject[key] = formatNumberValue(value);
      } else {
        paramsObject[key] = value;
      }
    }

    if (paramsObject.multiplier) {
      setSelectedMultiplier(filtersMultiplier.find(item => item.id === paramsObject.multiplier));
    }
    if (paramsObject.index) {
      setSelectedIndex(filtersIndex.find(item => item.id === paramsObject.index));
    }
    if (paramsObject.sector) {
      setSelectedSector(filtersSector.find(item => item.id === paramsObject.sector));
    }

    setIsInitialized(true);
  }, []);

  // Запрос данных
  const {loading, data, loadingError} = useTreeMapMultipliersData({isInitialized});

  const filteredBySectorData = useMemo(() => {
    return selectedSector.id === "all" ? data : data.filter(sector => sector.name === selectedSector.id);
  }, [data, selectedSector]);

  const filteredByIndexData= useMemo(() => {
    return filteredBySectorData.map(sector => {
      sector = {...sector};
      switch (selectedIndex.id) {
        case "iMOEX":
          sector.children = sector.children.filter(company => company.includeImoex);
          break;
        case "iMOEX+IE":
          sector.children = sector.children.filter(company => company.includeImoex || company.ieChoise);
          break;
        case "1%2C2":
          // 1-2 эшелоны
          sector.children = sector.children.filter(company => company.moexList <= 2)
          break;
        case "3":
          // 3 эшелон
          sector.children = sector.children.filter(company => company.moexList === 3)
          break;
      }
      return sector;
    }).filter(sector => sector.children.length > 0);
  }, [filteredBySectorData, selectedIndex.id])

  // console.log("selectedIndex", selectedIndex.id);
  // console.log("filteredBySectorData", filteredBySectorData);
  // console.log("filteredByIndexData", filteredByIndexData);

  // zoom-эффект карты рынка
  const {currentZoom, svgRef} = useZoom(loading, data, screenWidth);

  const isShowMap = data.length > 0 && !loading;

  if(loadingError) {
    navigate("/error-500");
  }

  return (
    <div className={styles.marketMapWrapper}>
      <div className={styles.filters}>
        <FiltersForMultipliersMap filtersIndex={filtersIndex} selectedIndex={selectedIndex}
                                  setSelectedIndex={setSelectedIndex} filtersSector={filtersSector}
                                  filtersMultiplicator={filtersMultiplier} selectedMultiplier={selectedMultiplier}
                                  setSelectedMultiplier={setSelectedMultiplier} setSelectedSector={setSelectedSector}
                                  selectedSector={selectedSector} loading={loading}/>
        {screenWidth > 1024 &&
          <div className={styles.screenMap} onClick={() => setIsFullscreen(!isFullscreen)}>
            <FullScreenIcon theme={theme} isFullscreen={isFullscreen}/>
            {isFullscreen ? 'Свернуть полный экран' : 'Открыть во весь экран'}
          </div>}
      </div>

      <div style={{width: '100%'}} className={styles.treemapContainer}>
        {isShowMap && (
          <div style={{
            position: 'relative',
            width: '100%',
            maxWidth: `${screenWidth}px`,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
            marginBottom: `${screenWidth <= 940 && '50px'}`
          }}
          >
            {screenWidth > 1440 ? <ResponsiveContainer
                className={isFullscreen ? styles.fullscreen : styles.collapseFullscreen}
                height={heightForTreemap(filteredByIndexData)} ref={svgRef}>

                <Treemap
                  data={filteredByIndexData}
                  dataKey="size"
                  nameKey="id"
                  stroke="#FFFFFF"
                  content={<CustomizedMultipliersContent currentZoom={currentZoom} selectedMultiplicator={selectedMultiplier}/>}
                  aspectRatio={0.9}
                  isAnimationActive={false}
                >
                  <Tooltip
                    content={<CustomMultipliersTooltip selectedMultiplier={selectedMultiplier}/>}
                    allowEscapeViewBox={{x: false, y: false}}
                    animationDuration={300}
                  />
                  <Tooltip content={<p>1234</p>}/>
                </Treemap>
              </ResponsiveContainer>
              : (
                <>
                  {filteredByIndexData.filter(sector => sector.children.length > 0).map((sector, index) => {
                    return <ResponsiveContainer key={index}
                                                className={isFullscreen ? styles.fullscreen : styles.collapseFullscreen}
                                                height={heightForMobileTreemap(sector)}
                    >
                      <div className={styles.sectorHeader}>{sector.name.toLowerCase()}</div>
                      <Treemap
                        data={[sector]}
                        dataKey="size"
                        nameKey="id"
                        stroke="#FFFFFF"
                        content={<CustomizedMultipliersContent currentZoom={currentZoom} selectedMultiplicator={selectedMultiplier}/>}
                        aspectRatio={1}
                        isAnimationActive={false}
                      >
                        <Tooltip
                          content={<CustomMultipliersTooltip selectedMultiplier={selectedMultiplier}/>}
                          allowEscapeViewBox={{x: false, y: true}}
                          animationDuration={0}
                          animationEasing={'linear'}
                        />
                      </Treemap>

                    </ResponsiveContainer>
                  })}
                </>
              )
            }
          </div>
        )}
        {!isShowMap && (
          <div className={styles.preloaderContainer}>
            <Preloader/>
            Скоро все загрузится. Пожалуйста, подождите
          </div>
        )}
      </div>
    </div>
  );
};
