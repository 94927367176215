import {useCallback, useEffect, useState, useRef} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {useDispatch, useSelector} from 'react-redux';
import {TwoLevelMenu, TwoLevelMenuOther, TwoLevelMenuOtherProfile,} from '../two-level-menu/two-level-menu';
import {extraining, portfel, profile} from '../../utils/twoLevelMenuData.js';
import {useActiveButtonCountryTagChoice} from '../../services/context/CountryFilterContext';
import {
  setAuthChecked,
  // setUser
} from '../../services/reducers/user-slice';
import doorExit from '../../images/door-exit.svg';
import InvestEraWideLogo from '../../images/ie-logo.svg';
import InvestEraSmallLogo from "../../images/logo-logo.svg";
import SearchInput from '../input-search/input-search';
import ActiveSunSvg from '../../images/active-sun-icon.svg';
import SunSvg from '../../images/sun-icon.svg';
import ActiveMoonSvg from '../../images/active-moon-icon.svg';
import MoonSvg from '../../images/moon-icon.svg';
import SearchLogo from '../../images/Serch.svg';
import Earth from '../../images/internet.svg';
import ArrowDown from '../../images/arrow-down.svg';
import ArrowUp from '../../images/arrow-up.svg';
import Close from '../../images/close-x-sign-svg.svg';
import UserLogo from '../../images/user.svg';
import {useTheme} from "../../services/hooks/useTheme";
import styles from './app-header-main.module.css';

function AppHeaderMain() {
  const isAuthChecked = useSelector((store) => store.user.isAuthChecked);
  const value = useActiveButtonCountryTagChoice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef(null);
  const {theme, toggleTheme} = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isOpenMenuHoveredTraining, setIsOverMenuHoveredTraining] = useState(false);
  const [isOpenMenuHoveredPortfel, setIsOverMenuHoveredPortfel] = useState(false);
  const [isOpenMenuHoveredProfile, setIsOverMenuHoveredProfile] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({
    profile: true,
    training: false,
    trainingSubBeginner: false,
    trainingSubExpert: false,
    trainingSubAdvanced: false,
    portfel: false,
  });

  const token = localStorage.getItem('token');

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
    if (!openMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  const navigateProfile = useCallback(() => {
    if (!token) {
      navigate('/authorization');
    } else {
      navigate('/profile');
    }
  }, []);

  const logout = useCallback(() => {
    value.setPopUpExitVision((prev) => !prev);
    // localStorage.removeItem("token")
    // localStorage.removeItem("subscription")
    // dispatch(setUser(null))
    // dispatch(setAuthChecked(false))
    // window.location.reload()
  }, []);

  useEffect(() => {
    // Проверяем, есть ли токен в localStorage
    const token = localStorage.getItem('token');
    // Если токен есть, то isAuthChecked должно быть true
    if (token) {
      dispatch(setAuthChecked(true));
    } else {
      // Если токен нет, то isAuthChecked должно быть false
      dispatch(setAuthChecked(false));
    }
  }, [isAuthChecked]);

  /*  const toggleTwoLevelMenuTraining = () => {
      setIsOverMenuHoveredTraining(true);
      setIsOverMenuHoveredPortfel(false);
      setIsOverMenuHoveredProfile(false);
    };*/

  const toggleTwoLevelMenuPortfel = () => {
    setIsOverMenuHoveredPortfel(true);
    setIsOverMenuHoveredTraining(false);
    setIsOverMenuHoveredProfile(false);
  };

  const toggleTwoLevelMenuProfile = () => {
    setIsOverMenuHoveredProfile(true);
    setIsOverMenuHoveredPortfel(false);
    setIsOverMenuHoveredTraining(false);
  };

  const closeAllTogglemenu = () => {
    setIsOverMenuHoveredTraining(false);
    setIsOverMenuHoveredPortfel(false);
    setIsOverMenuHoveredProfile(false);
  };

  const toggleSubMenu = (type) => {
    setOpenSubMenu((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  //временное решение
  const toggleOpenClosemenu = () => {
    setOpenMenu((prevState) => !prevState);
    if (openMenu) {
      setOpenMenu(false);
      document.body.style.overflow = '';
    }
  };

  const closeMenuForLogOut = () => {
    if (isAuthChecked) {
      logout();
    } else {
      navigateProfile();
    }
    toggleOpenClosemenu();
  };

  //закрытие окон если курсор вне header
  useEffect(() => {
    let timeoutId;

    const handleClickOutside = (event) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        if (headerRef.current && !headerRef.current.contains(event.target)) {
          closeAllTogglemenu();
        }
      }, 100);
    };

    document.addEventListener('mousemove', handleClickOutside);
    return () => {
      document.removeEventListener('mousemove', handleClickOutside);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  //close Modal for openSearch
  const closeSearch = () => {
    setOpenSearch((open) => !open);
    closeAllTogglemenu();
  };

  const isMobile = useMediaQuery({query: '(max-width: 1024px)'});
  return !isMobile ? (
    <header ref={headerRef} className={styles.header}>
      <div className={styles.container}>
        <NavLink to={'/'} className={styles.link}>
          <img className={styles.logo} src={InvestEraWideLogo} alt="logo"/>
        </NavLink>

        {openSearch && (
          <SearchInput setOpenSearch={setOpenSearch} setOpenMenu={setOpenMenu}/>
        )}
        {!openSearch && (
          <ul className={styles.textContainer}>
            <div className={styles.headerUp}>
              <li onMouseEnter={closeAllTogglemenu}>
                <NavLink to={`/`} className={styles.link}>
                  <button className={styles.buttonHeader}>
                    <span className={styles.text}>Главная</span>
                  </button>
                </NavLink>
              </li>
              <li onMouseEnter={closeAllTogglemenu}>
                <NavLink to={`/analytics-and-news`} className={styles.link}>
                  <button className={styles.buttonHeader} type={'submit'}>
                    <span className={styles.text}>Аналитика и новости</span>
                  </button>
                </NavLink>
              </li>
              <li onMouseEnter={closeAllTogglemenu}>
                <NavLink to={`/market-map`} className={styles.link}>
                  <button className={styles.buttonHeader} type={'submit'}>
                    <span className={styles.text}>Карта рынка</span>
                  </button>
                </NavLink>
              </li>
              <li onMouseEnter={closeAllTogglemenu} onClick={closeAllTogglemenu}>
                <NavLink to={'/education'} className={styles.link}>
                  <button className={styles.buttonHeader}>
                    <span className={styles.text}>Обучение</span>
                  </button>
                </NavLink>
              </li>
              <li onMouseEnter={toggleTwoLevelMenuPortfel} onClick={closeAllTogglemenu}>
                <NavLink to={'/tables/ideas-russia'} className={styles.link}>
                  <button className={styles.buttonHeader}>
                    <span className={styles.text}>Портфели</span>
                  </button>
                </NavLink>
              </li>
              <li onMouseEnter={toggleTwoLevelMenuProfile} onClick={closeAllTogglemenu}>
                <NavLink to={'/profile'} className={styles.link}>
                  <button className={styles.buttonHeader}>
                    <span className={styles.text}>Профиль</span>
                  </button>
                </NavLink>
              </li>
            </div>

            <div className={`${styles.headerUp} ${styles.headerUpDown}`}>
              <li onMouseEnter={closeAllTogglemenu}>
                <NavLink to={`/polezno-investoru`} className={styles.link}>
                  <button className={styles.buttonHeader}>
                    <span className={styles.text} style={{whiteSpace: 'nowrap'}}>
                      Полезно инвестору
                    </span>
                  </button>
                </NavLink>
              </li>
              <li onMouseEnter={closeAllTogglemenu}>
                <NavLink to={'/glossary'} className={styles.link}>
                  <button className={styles.buttonHeader}>
                    <span className={styles.text} style={{whiteSpace: 'nowrap'}}>
                      Глоссарий
                    </span>
                  </button>
                </NavLink>
              </li>
              <li onMouseEnter={closeAllTogglemenu}>
                <NavLink to={'/about-us'} className={styles.link}>
                  <button className={styles.buttonHeader}>
                    <span className={styles.text} style={{whiteSpace: 'nowrap'}}>
                      О нас
                    </span>
                  </button>
                </NavLink>
              </li>
            </div>
          </ul>
        )}

        <div className={styles.authorizationWrapper}>
          <div className={`${styles.themeWrapper} ${openSearch ? styles.hidden : ''}`}
               onMouseEnter={closeAllTogglemenu}>
            <img onClick={toggleTheme} className={styles.themeImg}
                 src={theme === 'app_light_theme' ? ActiveSunSvg : SunSvg}
                 alt="sun"/>
            <img onClick={toggleTheme} className={styles.themeImg}
                 src={theme === 'app_dark_theme' ? ActiveMoonSvg : MoonSvg}
                 alt="moon"/>
          </div>
          <div className={styles.searchLogo}>
            <img src={SearchLogo} alt="search" onClick={closeSearch}/>
          </div>
          {isAuthChecked && (
            <NavLink to={'/profile'} className={styles.userLogo}>
              <img src={UserLogo} alt="user"/>
            </NavLink>
          )}
          {location.pathname === '/authorization' || location.pathname === '/noaccess' ? null : (
            <button
              onClick={() => {
                value.toggleVisible(true);
              }}
              className={styles.earth}>
              <img src={Earth} alt="earth"/>
            </button>
          )}
          {location.pathname === '/authorization' || location.pathname === '/noaccess' ? null : (
            <div
              className={styles.buttonWrapper}
              onClick={isAuthChecked ? logout : navigateProfile}>
              <div className={styles.doorExitWrapper}>
                <img src={doorExit} className={styles.doorExit} alt={'exit'}/>
              </div>
              <div className={styles.txtBtn}>{isAuthChecked ? 'Выйти' : 'Войти'}</div>
            </div>
          )}
        </div>
      </div>
      {isOpenMenuHoveredTraining && (
        <TwoLevelMenu open={openModal} data={extraining} closeModal={closeAllTogglemenu}/>
      )}
      {isOpenMenuHoveredPortfel && (
        <TwoLevelMenuOther open={openModal} data={portfel} closeModal={closeAllTogglemenu}/>
      )}
      {isAuthChecked && isOpenMenuHoveredProfile && (
        <TwoLevelMenuOtherProfile
          open={openModal}
          data={profile}
          closeModal={closeAllTogglemenu}
          clazz={true}
        />
      )}
    </header>
  ) : (
    <header className={`${styles.header} ${openMenu ? styles.open : ''}`}>
      <div className={styles.container}>
        <div className={styles.containerUp}>
          <NavLink to={'/'} className={styles.link}>
            <img className={styles.logo} src={InvestEraSmallLogo} alt="logo"/>
          </NavLink>
          {openSearch && <SearchInput setOpenSearch={setOpenSearch} setOpenMenu={setOpenMenu}/>}
          <div className={styles.containerDown}>
            <div className={`${styles.themeWrapper} ${openSearch ? styles.hidden : ''}`}>
              <img onClick={toggleTheme} className={styles.themeImg}
                   src={theme === 'app_light_theme' ? ActiveSunSvg : ActiveMoonSvg}
                   alt="sun"/>
            </div>
            <div className={`${styles.searchLogo} ${openSearch ? styles.hidden : ''}`}>
              <img src={SearchLogo} alt="search" onClick={closeSearch}/>
            </div>
            {isAuthChecked && (
              <NavLink
                to={'/profile'}
                onClick={openMenu ? toggleOpenClosemenu : null}
                className={`${styles.userLogo} ${openSearch ? styles.hidden : ''}`}>
                <img src={UserLogo} alt="user"/>
              </NavLink>
            )}
            {location.pathname === '/authorization' || location.pathname === '/noaccess' ? null : (
              <button
                onClick={() => {
                  value.toggleVisible(true);
                }}
                className={styles.earth}
                style={openSearch ? {display: 'none'} : {}}>
                <img src={Earth} alt="earth"/>
              </button>
            )}
            <div
              className={`${styles.menuText} ${openSearch ? styles.hidden : ''}`}
              onClick={toggleMenu}>
              {openMenu ? <img src={Close} alt="close"/> : 'Меню'}
            </div>
          </div>
        </div>
        <div style={{display: openMenu ? 'flex' : 'none'}} className={styles.down}>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <div className={styles.listItemImgWrapper}>
                <NavLink onClick={toggleOpenClosemenu} className={styles.linkMenu} to={'/profile'}>
                  Профиль
                </NavLink>
                <img
                  onClick={() => toggleSubMenu('profile')}
                  src={openSubMenu.profile ? ArrowUp : ArrowDown}
                  alt="arrow"
                />
              </div>
              {isAuthChecked ? (
                <ul className={`${styles.subList} ${openSubMenu.profile ? '' : styles.hidden}`}>
                  <li onClick={toggleOpenClosemenu} className={styles.subListItem}>
                    <NavLink className={styles.linkMenu} to={'/profile'}>
                      Личный кабинет
                    </NavLink>
                  </li>
                  <li
                    onClick={isAuthChecked ? logout : navigateProfile}
                    className={styles.subListItem}>
                    Выход
                  </li>
                </ul>
              ) : (
                <ul className={`${styles.subList} ${openSubMenu.profile ? '' : styles.hidden}`}>
                  <li className={styles.subListItem} onClick={toggleOpenClosemenu}>
                    <NavLink className={styles.linkMenu} to="/authorization">
                      Войти
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li onClick={toggleOpenClosemenu} className={styles.listItem}>
              <NavLink to={'/analytics-and-news'} className={styles.linkMenu}>
                Аналитика и новости
              </NavLink>
            </li>
            <li onClick={toggleOpenClosemenu} className={styles.listItem}>
              <NavLink to={'/market-map'} className={styles.linkMenu}>
                Карта рынка
              </NavLink>
            </li>

            <li className={styles.listItem}>
              <div className={styles.listItemImgWrapper}>
                <NavLink
                  onClick={toggleOpenClosemenu}
                  className={styles.linkMenu}
                  to={`/tables/ideas-russia`}>
                  Портфели
                </NavLink>
                <img
                  onClick={() => toggleSubMenu('portfel')}
                  src={openSubMenu.portfel ? ArrowUp : ArrowDown}
                  alt="arrow"
                />
              </div>
              <ul className={`${styles.subList} ${openSubMenu.portfel ? '' : styles.hidden}`}>
                {portfel?.map((item) => (
                  <li key={item.id} className={styles.subListItem}>
                    <NavLink
                      onClick={toggleOpenClosemenu}
                      className={styles.linkMenu}
                      to={`/tables/${item.navigate}`}>
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>

            <li className={styles.listItem}>
              <div className={styles.listItemImgWrapper}>
                <NavLink
                  onClick={toggleOpenClosemenu}
                  className={styles.linkMenu}
                  to={`/education`}>
                  Обучение
                </NavLink>
              </div>
            </li>
            <li onClick={toggleOpenClosemenu} className={styles.listItem}>
              <NavLink className={styles.linkMenu} to={`/polezno-investoru`}>
                Полезно инвестору
              </NavLink>
            </li>
            <li onClick={toggleOpenClosemenu} className={styles.listItem}>
              <NavLink className={styles.linkMenu} to={'/glossary'}>
                Глоссарий
              </NavLink>
            </li>
            <li onClick={toggleOpenClosemenu} className={styles.listItem}>
              <NavLink className={styles.linkMenu} to={'/about-us'}>
                О компании
              </NavLink>
            </li>
          </ul>
          <div className={styles.buttonWrapperMobile} onClick={closeMenuForLogOut}>
            <div className={styles.doorExitWrapper}>
              <img src={doorExit} className={styles.doorExit} alt={'exit'}/>
            </div>
            <div className={styles.txtBtn}>{isAuthChecked ? 'Выйти' : 'Войти'}</div>
          </div>
        </div>
      </div>

      <div className={styles.buttonWrapper} onClick={closeMenuForLogOut}>
        <div className={styles.doorExitWrapper}>
          <img src={doorExit} className={styles.doorExit} alt={'exit'}/>
        </div>
        <div className={styles.txtBtn}>{isAuthChecked ? 'Выйти' : 'Войти'}</div>
      </div>
    </header>
  );
}

export default AppHeaderMain;
