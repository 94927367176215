import styles from "./faq.module.css";
import Close from "../../images/close.svg";
import Open from "../../images/open.svg";
import React from "react";
import {useFaq} from "./useFaq";

export const Faq = () => {
  const {questions, onToggleOpen} = useFaq();

  return (
    <div className={styles.faq}>
      <div className="content">
        <h2 className={styles.question}>Часто задаваемые вопросы</h2>
        <div className={styles.wrapperQuestions}>
          {questions &&
            questions.map((item) => (
              <div key={item.id} className={styles.questionItem}>
                <div className={styles.questionItemDescription}>
                  <h3 className={styles.questionSubtitle}>{item.title}</h3>
                  {item.open &&
                    item.descr.map((line, index) => (
                      <p key={index} className={styles.paragraph}>
                        {line}
                      </p>
                    ))}
                </div>
                <div className={styles.close} onClick={() => onToggleOpen(item.id)}>
                  {item.open ? <img src={Close} alt="close"/> : <img src={Open} alt="open"/>}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
